import notFound from "../resources/404.png";

const NotFound = () => {
  return (
    <>
      <img src={notFound} alt="404" />
    </>
  );
};

export default NotFound;
